import { Component, Input, OnInit } from "@angular/core";
import { ModalData } from "../modal.service";
import { ModalHeaderI } from "../modal.header.component";
import { Conflict } from "../../s25-conflicts/s25.conflicts.component";

export interface DraftConflictsModal extends ModalData, ModalHeaderI {
    conflicts: Conflict[][];
}

@Component({
    template: `
        @if (initModal) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    <div id="DraftConflictsModalExplanation">
                        <span class="ngBold"
                            >{{ data.lang.div.controls.draft_conflicts.not_saved }}
                            {{ data.lang.div.controls.draft_conflicts.explanation }}</span
                        >
                    </div>
                    <br />
                    <s25-ng-conflicts [model]="data.conflicts.flat()"></s25-ng-conflicts>
                </div>
                <div class="modal-footer">
                    <div class="ngCenterAlignText">
                        <div>
                            Please edit this draft via the Event Form to address the conflicts and update the event
                            state.
                        </div>
                        <br />
                        <div align="right">
                            <button class="aw-button aw-button--primary" (click)="data.closeModal()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        }
    `,
})
export class ModalDraftConflictsComponent implements OnInit {
    @Input() data: DraftConflictsModal;
    initModal = false;

    ngOnInit(): void {
        this.initModal = true;
    }
}
