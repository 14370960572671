import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-inactive-task-modal")
@Component({
    selector: "s25-ng-inactive-task-modal",
    template: `
        @if (isInit) {
            <div class="modal-body c-padding-bottom--single">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="ngBold c-padding-bottom--half">{{ taskName }}</div>
                <div>Blocked By {{ data.blockTask.length > 1 ? "Tasks" : "Task" }} Type:</div>
                @for (d of data.blockTask; track d) {
                    <div class="c-padding-bottom--half capitalize c-padding-left--single">
                        {{ d.blocking_task_type }}
                    </div>
                }
                <!-- display Contacts that need to approve tasks -->
                @if (approvalState && approvalState.length > 0) {
                    <div>
                        <div class="ngBold c-padding-bottom--half ">Pending Task Assignees</div>
                        @for (approval of approvalState; track approval) {
                            <div>
                                <div class="c-padding-left--single">{{ approval[0] }}</div>
                                @for (contact of approval[1]; track contact) {
                                    <div class="c-padding-left--double">
                                        {{ contact }}
                                        <div></div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .capitalize {
            text-transform: capitalize;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalInactiveTaskComponent implements OnInit {
    @Input() data: any;

    isInit: boolean;
    contactList: any[];
    approvalState: any[];
    taskName: string;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.data.blockTask = S25Util.array.forceArray(this.data.blockTask);
        this.data.assignedTo && this.data.assignedTo.task && this.data.assignedTo.task.approval_contact
            ? this.getApprovalStatuses()
            : "";
        this.taskName = this.data.taskName || this.data.actions.taskName;
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    /**
     * Finds all the contacts associated with the current task
     * adds them to this.approvalState with their task status authorized, in progress etc.
     */
    getApprovalStatuses() {
        const messages: { [key: string]: any[] } = {};

        for (let contact of this.data.assignedTo.task.approval_contact) {
            const status = `${contact.notification_type_name} ${contact.approval_contact_state_name
                .split("/")
                .join("/ ")}`;
            messages[status] ??= [];
            messages[status].push(contact.approval_contact_name);
        }

        this.approvalState = Object.entries(messages)
            .map(([type, arr]) => [type, arr.sort()])
            .sort();
    }
}
