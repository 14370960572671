import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { EventService } from "../../../services/event.service";
import { ContactService } from "../../../services/contact.service";
import { EventStateChangeService } from "../../../services/event.state.change.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { TaskService } from "../../../services/task/task.service";
import { ModalHeaderI } from "../modal.header.component";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";

export interface ModalCancelRequestFormI extends ModalHeaderI {
    itemId: number;
    assigneeId: number;
    onDone: () => void;
}

@Component({
    template: `
        @if (isInit) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    @if (!success && !msg) {
                        <div>
                            <s25-loading-inline [model]="{}"></s25-loading-inline>
                            <div>
                                <label for="taskComment" class="ngBold"
                                    >Please enter a reason for cancelling this event</label
                                >
                            </div>
                            <span>
                                <textarea [(ngModel)]="comment" id="taskComment" class="c-fullWidth"></textarea>
                            </span>
                        </div>
                    }
                    @if (success) {
                        <div>Your cancellation request has been submitted.</div>
                    }
                    @if (msg) {
                        <div class="ngRed">{{ msg }}</div>
                    }
                </div>
                <div class="modal-footer">
                    <button [disabled]="!comment" (click)="submit()" class="aw-button aw-button--primary">
                        Request Cancellation
                    </button>
                </div>
            </div>
        }
    `,
    styles: ["textarea {min-height: 10em}"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCancelRequestComponent implements OnInit {
    @Input() data: ModalCancelRequestFormI;

    comment: string;
    todo: any;
    success: boolean;
    msg: string;
    isInit: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        S25Util.all({
            eventData: EventService.getEventInclude(this.data.itemId, ["workflow", "reservations", "customers"]),
            currentUserId: ContactService.getCurrentId(),
            assignedToId: this.data.assigneeId || EventStateChangeService.getTaskAssignee(this.data.itemId),
        }).then((data) => {
            this.todo = {
                eventId: this.data.itemId,
                assignedToId: data.assignedToId,
                assignedById: data.currentUserId,
                dueDate: S25Datefilter.transform(new Date(), "yyyy-MM-dd"), // ANG-4493 use current date instead of data.eventData.start_date,
                taskName: "Cancel Request",
                type: 1,
                subType: 99,
            };
            this.isInit = true;
            this.cd.detectChanges();
        });
    }

    submit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.todo.taskComment = this.comment;
        TaskService.createEventTodo(this.todo).then(
            (resp) => {
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                this.comment = null;
                this.success = true;
                this.data.onDone();
                this.cd.detectChanges();
            },
            (err) => {
                if (err.data.results.error.msg_id === "SY_E_DATAERROR") {
                    this.msg = `Error: Assignee does not have permission to view this event.`;
                } else {
                    this.msg = S25Util.errorText(err);
                }

                this.cd.detectChanges();
                S25LoadingApi.destroy(this.elementRef.nativeElement);
            },
        );
    }
}
