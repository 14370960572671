import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";
import { Rules } from "../../s25-rule-tree/s25.rule.const";

@Component({
    template: `
        @if (isInit) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    <s25-ng-rule [rule]="data.rule" (saved)="closeAndRefresh()" (canceled)="closeModal()"></s25-ng-rule>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditRuleComponent implements OnInit {
    @Input() data: ModalHeaderI & { rule: Rules.Rule; refresh: VoidFunction };
    isInit: boolean = false;

    ngOnInit() {
        this.isInit = true;
    }

    closeModal() {
        this.data.closeModal();
    }

    closeAndRefresh() {
        this.closeModal();
        this.data.refresh();
    }
}
