import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";

@Component({
    template: `
        @if (isInit) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    <s25-ng-masquerade-switch-roles
                        [isModal]="false"
                        (masquerade)="onMasquerade($event)"
                        (cancelled)="onCancelled()"
                    ></s25-ng-masquerade-switch-roles>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSwitchRoleComponent implements OnInit {
    @Input() data: {
        items: any;
        reference?: string;
        onSave?: Function;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
