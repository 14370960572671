// @author: travis
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewRef } from "@angular/core";
import { ModalInterface } from "../modal.interface";
import { jSith } from "../../../util/jquery-replacement";
import { DropDownItem } from "../../../pojo/DropDownItem";

export interface ModalImageI {
    itemId?: number;
    isUpload?: boolean;
    falseLabel?: string;
    trueLabel?: string;
    title?: string;
    errorMsg?: string;
    selectModel?: any;
    imageUrl?: string;
    onDone?: Function;
    save?: Function;
    closeModal?: Function;
    close?: Function;
    imageModel?: any; //ImageModelI
    imageTypeName?: string;
    hasUploadPerms?: boolean;
}

@Component({
    template: `
        <!--<div *ngIf="initModal" class="s25-ng">
        <s25-modal-header [data]="data"></s25-modal-header>
        <div class="modal-body ngCenterAlignText">
          Images upload form goes here
        </div>
        <div class="modal-footer">
          &nbsp;<button href="javascript:void(0);" class="aw-button aw-button--outline" (click)="data.save()">Upload</button>
        </div>
        </div>-->
        @if (initModal) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body c-custAttr-imageUpload">
                    @if (data.hasUploadPerms) {
                        <div class="c-custAttr-imageUpload-btn-wrapper">
                            <s25-toggle-button
                                (modelValueChange)="onToggleChange($event)"
                                [modelValue]="data.isUpload"
                                [falseLabel]="'Select'"
                                [trueLabel]="'Upload'"
                            ></s25-toggle-button>
                        </div>
                    }
                    @if (data.isUpload && data.hasUploadPerms) {
                        <div>
                            <label class="c-custAttr-imgDescLabels"
                                >Image Name:
                                <s25-ng-editable-text
                                    [alwaysEditing]="true"
                                    [allowEmpty]="true"
                                    [(val)]="data.imageModel.imageName"
                                ></s25-ng-editable-text>
                            </label>
                            <label class="c-custAttr-imgDescLabels"
                                >Image Description (optional):
                                <s25-ng-editable-text
                                    [alwaysEditing]="true"
                                    [allowEmpty]="true"
                                    [(val)]="data.imageModel.imageDesc"
                                ></s25-ng-editable-text>
                            </label>
                            <label class="c-custAttr-imgDescLabels"
                                >Image Type:
                                <s25-generic-dropdown
                                    [items]="this.types"
                                    [(chosen)]="defaultType"
                                    (chosenChange)="chooseType($event)"
                                ></s25-generic-dropdown>
                            </label>
                            <s25-ng-image-upload
                                [isModal]="true"
                                [model]="data.imageModel"
                                (uploadedImageChange)="onImageUpload($event)"
                            ></s25-ng-image-upload>
                        </div>
                    }
                    @if (!data.isUpload || !data.hasUploadPerms) {
                        <div>
                            <s25-ng-image-selector
                                [(model)]="data.selectModel"
                                (modelChange)="onSelect($event)"
                            ></s25-ng-image-selector>
                        </div>
                    }
                </div>
                <div class="modal-footer">
                    @if (data.errorMsg) {
                        <div class="ngRed">{{ data.errorMsg }}</div>
                    }
                    &nbsp;<button href="javascript:void(0);" class="aw-button aw-button--primary" (click)="data.save()">
                        {{ buttonText }}
                    </button>
                </div>
            </div>
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalImageUploadComponent implements OnInit, ModalInterface {
    @Input() data: ModalImageI; //{itemId: number, isUpload: boolean, falseLabel: string, trueLabel: string}
    initModal: boolean = false;
    buttonText: string;
    defaultType: DropDownItem;

    types: DropDownItem[] = [
        {
            itemId: 1,
            itemName: "Photograph",
        },
        {
            itemId: 2,
            itemName: "Diagram",
        },
    ];

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.buttonText = this.data.hasUploadPerms ? "Upload" : "Select";
        if (!this.data.hasUploadPerms) this.data.isUpload = false;
        this.data.title = this.data.hasUploadPerms ? "Image Upload" : "Image Select";
        this.data.isUpload = this.data.isUpload || true;
        this.data.selectModel = {};

        this.data.save = this.save;
        this.data.errorMsg = null;
        this.data.imageModel = {};

        this.defaultType = this.data.imageTypeName === "Image" ? this.types[0] : this.types[1];
        this.data.imageModel.imageTypeId = this.defaultType.itemId;

        this.initModal = true;
    }

    save = () => {
        let origText = this.buttonText;
        this.buttonText = this.data.isUpload ? "Uploading..." : "Saving...";
        this.cd.detectChanges();
        jSith.when(this.data.onDone()).then(() => {
            this.buttonText = origText;
            this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
        });
    };

    onToggleChange(data: any) {
        this.data.errorMsg = null;
        this.data.isUpload = data;
        this.buttonText = this.data.isUpload ? "Upload" : "Select";
        this.cd.detectChanges();
    }

    onSelect(data: any) {
        this.data.errorMsg = null;
    }

    chooseType(data: any) {
        this.data.imageModel.imageTypeId = data.itemId;
    }

    onImageUpload(data: any) {
        this.data.errorMsg = this.data.imageModel.getErrorMsg();
    }
}
