import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ModalInterface } from "../modal.interface";

@Component({
    template: `
        <div class="s25-ng">
            <s25-modal-header [data]="$any(data)"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-event-form-preview
                    [formModel]="$any(data).formModel"
                    [consolidatedOccObj]="true"
                ></s25-ng-event-form-preview>
            </div>
            <div class="modal-footer"></div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEventFormPreviewComponent implements ModalInterface {
    @Input() data: ModalInterface;
}
