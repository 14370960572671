//@author: devin
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalInterface } from "../modal.interface";

@Component({
    template: `
        @if (initModal) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body ngCenterAlignText">
                    @if (data.isUnsupportedBrowser) {
                        <div>
                            <strong
                                >Use of Internet Explorer Is no Longer Officially Supported (as of August 20,
                                2020)</strong
                            >
                            <p>
                                Please upgrade to Microsoft's official browser, Microsoft Edge, the official recommended
                                browser for Windows, or one of the other supported browsers listed below.
                            </p>
                            <p>25Live works best and fastest in Google Chrome, Microsoft Edge, and Mozilla Firefox.</p>
                        </div>
                    }
                    <p></p>
                    <div>
                        <span
                            >25Live works best and fastest in
                            <a href="http://www.google.com/chrome/" target="chrome">Google Chrome</a>,
                            <a href="https://www.microsoft.com/en-us/edge" target="edge">Microsoft Edge</a>, and
                            <a href="http://www.mozilla.com/firefox/" target="firefox">Mozilla Firefox</a>.
                        </span>
                    </div>
                    <br />
                    <p>
                        <a href="http://www.google.com/chrome/" target="chrome"
                            ><span class="ngChrome ngInlineBlock"></span
                        ></a>
                        <a href="https://www.microsoft.com/en-us/edge" target="edge"
                            ><span class="ngEdge ngInlineBlock"></span
                        ></a>
                        <a href="http://www.mozilla.com/firefox/" target="firefox"
                            ><span class="ngFirefox ngInlineBlock"></span
                        ></a>
                    </p>
                </div>
                <div class="modal-footer">
                    &nbsp;<button
                        href="javascript:void(0);"
                        class="aw-button aw-button--outline"
                        (click)="data.closeModal()"
                    >
                        Close
                    </button>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalRecommendedBrowsersComponent implements OnInit, ModalInterface {
    @Input() data: any;
    initModal: boolean = false;

    ngOnInit() {
        this.initModal = true;
    }
}
