import { Component, Input, OnInit } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { ModalData } from "../modal.service";
import { ModalHeaderI } from "../modal.header.component";

export interface CabinetSelectModal extends ModalData, ModalHeaderI {
    parent: DataParent;

    onDone(parentId: number): void;
}

interface DataParent {
    parent_id: string;
    parent_name: any;
    parent_description: any;
}

interface Parent {
    id: number;
    name: string;
}

@Component({
    template: `
        @if (isInit) {
            <div class="s25-ng">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="modal-body">
                    <p align="center">
                        <span class="ngItalic">{{
                            data.lang.div.controls["s25-rsrv_wiz"].text.diagnose.which_heading
                        }}</span>
                    </p>
                    @for (parent of parents; track parent) {
                        <label class="ngBlock ngCpointer">
                            <input class="ngAlignInput" type="radio" [(ngModel)]="parentId" [value]="parent.id" />
                            {{ parent.name }}
                        </label>
                    }
                    <div class="ngPadBottom10">&nbsp;</div>
                </div>
                <div class="modal-footer">
                    <button class="aw-button aw-button--primary" (click)="save()">Save</button>
                </div>
            </div>
        }
    `,
})
export class ModalCabinetSelectComponent implements OnInit {
    @Input() data: CabinetSelectModal;
    parents: Parent[];
    parentId: number;
    isInit: boolean;

    ngOnInit() {
        const parents: DataParent[] = S25Util.array.forceArray(S25Util.propertyGet(this.data, "parent"));
        this.parents = parents.map((parent) => ({
            id: parseInt(parent.parent_id),
            name: parent.parent_name,
            descr: parent.parent_description,
        }));
        this.parents.sort(S25Util.shallowSort("name"));
        this.parentId = parseInt(this.data.parent.parent_id);
        this.isInit = true;
    }

    close() {
        this.data.closeModal();
    }

    save() {
        this.data.closeModal();
        this.data.onDone(this.parentId);
    }
}
