import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalData } from "../modal.service";
import { ModalHeaderI } from "../modal.header.component";
import { StateService } from "../../../services/state.service";
import { ResourceService } from "../../../services/resource.service";
import { SpaceService } from "../../../services/space.service";
import { ContactService } from "../../../services/contact.service";
import { OrganizationService } from "../../../services/organization.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { Location } from "../../../pojo/Location";

export interface ModalCreateObjectModel extends ModalData, ModalHeaderI {
    objectTypeId: 2 | 3 | 4 | 6;
    objectName: "Organization" | "Contact" | "Location" | "Resource";
    eventForm?: boolean;
}

@TypeManagerDecorator("s25-ng-create-object-modal")
@Component({
    selector: "s25-ng-create-object-modal",
    template: `
        @if (init) {
            <div class="createObjectForm" tabindex="0" [attr.aria-label]="'Create ' + data.objectName + ' Form'">
                <s25-modal-header [data]="data"></s25-modal-header>
                <div class="loadIndicator">
                    <s25-loading-inline [model]="{ text: 'Saving...' }"></s25-loading-inline>
                </div>
                @if (data.objectTypeId === 2) {
                    <form class="orgForm" [formGroup]="orgForm" (ngSubmit)="createOrg()">
                        <div>
                            <div>
                                <label for="orgName">Organization Name</label>
                                <span class="required">*</span>
                            </div>
                            <input
                                id="orgName"
                                formControlName="orgName"
                                class="c-input"
                                [ngClass]="{ 'error-outline': orgName.errors && isSubmitted }"
                            />
                            @if (
                                orgName.errors &&
                                (orgName.dirty || orgName.touched || (!orgName.touched && isSubmitted))
                            ) {
                                <div class="error-message" tabindex="0">
                                    @if (orgName.errors.maxlength) {
                                        <div>Please enter an Organization Name containing 40 characters or less</div>
                                    }
                                    @if (orgName.errors.required) {
                                        <div>Organization Name is required</div>
                                    }
                                </div>
                            }
                            @if (this.errorMsg) {
                                <div class="error-message" tabindex="0">{{ this.errorMsg }}</div>
                            }
                            <label for="orgTitle" class="c-margin-top--single">Organization Title</label>
                            <input
                                id="orgTitle"
                                formControlName="orgTitle"
                                class="c-input"
                                [ngClass]="{ 'error-outline': orgTitle.errors && isSubmitted }"
                            />
                            @if (orgTitle.errors && (orgTitle.dirty || orgTitle.touched)) {
                                <div class="error-message" tabindex="0">
                                    @if (orgTitle.errors.maxlength) {
                                        <div>Please enter an Organization Title containing 80 characters or less</div>
                                    }
                                </div>
                            }
                        </div>
                        <div class="buttonGroup">
                            <button class="aw-button aw-button--primary" [disabled]="disableSaveButton">
                                Add Organization
                            </button>
                        </div>
                    </form>
                }
                @if (data.objectTypeId === 3 && !extendedContactForm) {
                    <form [formGroup]="contactForm" class="contactForm" (ngSubmit)="findDuplicateContacts()">
                        @if (eventForm) {
                            <div>
                                @if (!showSuccessMsg) {
                                    <div class="contact-details-msg">
                                        Basic contact information can be provided here, and additional data can be added
                                        in the Contact Details view after the contact is created.
                                    </div>
                                }
                                @if (showSuccessMsg) {
                                    <div class="success-msg">
                                        <div>
                                            <svg class="c-svgIcon" role="img">
                                                <title>Success</title>
                                                <use
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                                                ></use>
                                            </svg>
                                            Contact Successfully Created
                                        </div>
                                        <div class="modal-footer">
                                            <button class="aw-button aw-button--outline" (click)="goToRecord()">
                                                View Contact Details
                                            </button>
                                            <button
                                                class="aw-button aw-button--outline c-margin-left--single"
                                                (click)="data.closeModal()"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        @if (!showSuccessMsg) {
                            <div>
                                <label for="fname">First Name</label>
                                <input
                                    id="fname"
                                    formControlName="fname"
                                    class="c-input"
                                    [ngClass]="{ 'error-outline': fname.errors && isSubmitted }"
                                />
                                @if (fname.errors && (fname.dirty || fname.touched)) {
                                    <div class="error-message" tabindex="0">
                                        @if (fname.errors.maxlength) {
                                            <div>Please enter a First Name containing 40 characters or less</div>
                                        }
                                    </div>
                                }
                                <label for="mname" class="c-margin-top--single">Middle Name</label>
                                <input
                                    id="mname"
                                    formControlName="mname"
                                    class="c-input"
                                    [ngClass]="{ 'error-outline': mname.errors && isSubmitted }"
                                />
                                @if (mname.errors && (mname.dirty || mname.touched)) {
                                    <div class="error-message" tabindex="0">
                                        @if (mname.errors.maxlength) {
                                            <div>Please enter a Middle Name containing 80 characters or less</div>
                                        }
                                    </div>
                                }
                                <div>
                                    <label for="lname" class="c-margin-top--single">Last Name</label>
                                    <span class="required">*</span>
                                </div>
                                <input
                                    id="lname"
                                    formControlName="lname"
                                    class="c-input"
                                    [ngClass]="{ 'error-outline': lname.errors && isSubmitted }"
                                />
                                @if (
                                    lname.errors && (lname.dirty || lname.touched || (!lname.touched && isSubmitted))
                                ) {
                                    <div class="error-message" tabindex="0">
                                        @if (lname.errors.maxlength) {
                                            <div>Please enter a Last Name containing 40 characters or less</div>
                                        }
                                        @if (lname.errors.required) {
                                            <div tabindex="0">Last Name is required</div>
                                        }
                                    </div>
                                }
                                <label for="phone" class="c-margin-top--single">Work Phone</label>
                                <input id="phone" formControlName="phone" class="c-input" type="text" name="phone" />
                                @if (phone.errors && (phone.dirty || phone.touched)) {
                                    <div class="error-message" tabindex="0">
                                        @if (phone.errors.maxlength) {
                                            <div>Please enter a Work Phone containing 24 characters or less</div>
                                        }
                                        @if (phone.errors.pattern) {
                                            <div>Please enter a valid phone number</div>
                                        }
                                    </div>
                                }
                                <div>
                                    <label for="email" class="c-margin-top--single">Work Email</label>
                                    <span class="required">*</span>
                                </div>
                                <input
                                    id="email"
                                    formControlName="email"
                                    class="c-input"
                                    [email]="true"
                                    [ngClass]="{ 'error-outline': email.errors && isSubmitted }"
                                />
                                @if (
                                    email.errors && (email.dirty || email.touched || (!email.touched && isSubmitted))
                                ) {
                                    <div class="error-message" tabindex="0">
                                        @if (email.errors.required) {
                                            <div>Work Email is required</div>
                                        }
                                        @if (email.errors.maxlength) {
                                            <div>Please enter a Work Email containing 80 characters or less</div>
                                        }
                                        @if (email.errors.email) {
                                            <div>Please enter a valid email</div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        @if (this.errorMsg) {
                            <div class="error-message" tabindex="0">{{ this.errorMsg }}</div>
                        }
                        @if (!showSuccessMsg) {
                            <div class="buttonGroup">
                                @if (dupContactMessage) {
                                    <div>
                                        <p class="error-message">{{ dupContactMessage }}</p>
                                        @if (exactContactMatches.length > 0) {
                                            <div class="match-container">
                                                @for (match of exactContactMatches; track match) {
                                                    <p class="error-message">{{ match }}</p>
                                                }
                                            </div>
                                        }
                                        <div class="dup-buttons">
                                            <button
                                                (click)="findDuplicateContacts()"
                                                class="aw-button aw-button--danger--outline"
                                            >
                                                Confirm
                                            </button>
                                            <button (click)="cancelConfirm()" class="aw-button aw-button--outline">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                }
                                @if (!dupContactMessage) {
                                    <div class="submit-buttons">
                                        <button
                                            type="submit"
                                            class="aw-button aw-button--primary"
                                            [disabled]="disableSaveButton"
                                        >
                                            {{ disableSaveButton ? "" : "Add Contact" }}
                                            <s25-loading-inline [model]="{}"></s25-loading-inline>
                                        </button>
                                        @if (eventForm) {
                                            <button class="aw-button aw-button--outline" (click)="toggleContactForm()">
                                                Additional Details
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </form>
                }
                @if (data.objectTypeId === 3 && eventForm && extendedContactForm) {
                    <s25-ng-contact-info
                        [isNew]="true"
                        [fields]="{ hasEmailOptions: false, showEmail: true, showAddress: true }"
                        [data]="{
                            eventForm: eventForm,
                            contactTransferData: contactTransferData,
                            closeModal: data.closeModal,
                        }"
                    ></s25-ng-contact-info>
                }
                @if (data.objectTypeId === 4) {
                    <form [formGroup]="locationForm" class="locationForm" (ngSubmit)="createLocation()">
                        <div>
                            <div>
                                <label for="locationName">Location Name</label>
                                <span class="required">*</span>
                            </div>
                            <input
                                id="locationName"
                                formControlName="locationName"
                                class="c-input"
                                [ngClass]="{ 'error-outline': locationName.errors && isSubmitted }"
                            />
                            @if (
                                locationName.errors &&
                                (locationName.dirty || locationName.touched || (!locationName.touched && isSubmitted))
                            ) {
                                <div class="error-message" tabindex="0">
                                    @if (locationName.errors.maxlength) {
                                        <div>Please enter a Location Name containing 40 characters or less</div>
                                    }
                                    @if (locationName.errors.required) {
                                        <div>Location Name is required</div>
                                    }
                                </div>
                            }
                            @if (this.errorMsg) {
                                <div class="error-message" tabindex="0">{{ this.errorMsg }}</div>
                            }
                            <label for="formalName" class="c-margin-top--single">Location Formal Name</label>
                            <input
                                id="formalName"
                                formControlName="formalName"
                                class="c-input"
                                [ngClass]="{ 'error-outline': formalName.errors && isSubmitted }"
                            />
                            @if (formalName.errors && (formalName.dirty || formalName.touched)) {
                                <div class="error-message" tabindex="0">
                                    @if (formalName.errors.maxlength) {
                                        <div>Please enter a Location Formal Name containing 80 characters or less</div>
                                    }
                                </div>
                            }
                            <div>
                                <label for="maxCapacity" class="c-margin-top--single">Max Capacity</label>
                                <span class="required">*</span>
                            </div>
                            <input
                                type="number"
                                id="maxCapacity"
                                formControlName="maxCapacity"
                                class="c-numeric-input"
                                [ngClass]="{ 'error-outline': maxCapacity.errors && isSubmitted }"
                            />
                            @if (
                                maxCapacity.errors &&
                                (maxCapacity.dirty || maxCapacity.touched || (!maxCapacity.touched && isSubmitted))
                            ) {
                                <div class="error-message" tabindex="0">Max Capacity is required</div>
                            }
                        </div>
                        <div class="buttonGroup">
                            <button class="aw-button aw-button--primary" [disabled]="disableSaveButton">
                                Add Location
                            </button>
                        </div>
                    </form>
                }
                @if (data.objectTypeId === 6) {
                    <form [formGroup]="resourceForm" class="resourceForm" (ngSubmit)="createResource()">
                        <div>
                            <div>
                                <label for="resourceName">Resource Name</label>
                                <span class="required">*</span>
                            </div>
                            <input
                                id="resourceName"
                                formControlName="resourceName"
                                class="c-input"
                                [ngClass]="{ 'error-outline': resourceName.errors && isSubmitted }"
                            />
                            @if (
                                resourceName.errors &&
                                (resourceName.dirty || resourceName.touched || (!resourceName.touched && isSubmitted))
                            ) {
                                <div class="error-message" tabindex="0">
                                    @if (resourceName.errors.required) {
                                        <div>Resource Name is required</div>
                                    }
                                    @if (resourceName.errors.maxlength) {
                                        <div>Please enter a Resource Name containing 40 characters or less</div>
                                    }
                                </div>
                            }
                            @if (this.errorMsg) {
                                <div class="error-message" tabindex="0">{{ this.errorMsg }}</div>
                            }
                        </div>
                        <div class="buttonGroup">
                            <button class="aw-button aw-button--primary" [disabled]="disableSaveButton">
                                Add Resource
                            </button>
                        </div>
                    </form>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalCreateObjectComponent implements OnInit {
    @Input() data: ModalCreateObjectModel;

    init = false;
    errorMsg: string = "";
    isSubmitted: boolean;
    disableSaveButton: boolean = false;
    extendedContactForm: boolean = false;
    eventForm: boolean;
    showSuccessMsg: boolean;
    dupContactMessage: string;
    exactContactMatches: string[] = [];
    contactTransferData: any;
    newContactId: number;

    contactForm = this.fb.group({
        fname: ["", [Validators.maxLength(40)]],
        lname: ["", [Validators.required, Validators.maxLength(40)]],
        mname: ["", [Validators.maxLength(80)]],
        phone: ["", [Validators.maxLength(24), Validators.pattern("[- +()0-9]+")]],
        email: ["", [Validators.required, Validators.maxLength(80), Validators.email]],
    });

    orgForm = this.fb.group({
        orgName: ["", [Validators.required, Validators.maxLength(40)]],
        orgTitle: ["", [Validators.maxLength(80)]],
    });

    locationForm = this.fb.group({
        locationName: ["", [Validators.required, Validators.maxLength(40)]],
        formalName: ["", [Validators.maxLength(80)]],
        maxCapacity: [0, [Validators.required, Validators.min(1)]],
    });

    resourceForm = this.fb.group({
        resourceName: ["", [Validators.required, Validators.maxLength(40)]],
    });

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit() {
        this.eventForm = S25Util.isDefined(this.data.eventForm);
        this.init = true;
        this.cd.detectChanges();
    }

    errorFocus() {
        setTimeout(() => {
            const elem: HTMLElement = this.elementRef.nativeElement.querySelector(".error-message");
            elem?.focus();
        });
    }

    createResource() {
        this.isSubmitted = true;

        if (this.resourceForm.invalid) {
            return this.errorFocus();
        }

        const resourceForm: HTMLElement = document.querySelector(".createObjectForm");
        S25LoadingApi.init(resourceForm);
        this.disableSaveButton = true;

        const payload = {
            resource_name: this.resourceName.value,
        };

        return ResourceService.createResource(payload)
            .then((resp) => {
                this.errorMsg = "";
                this.isSubmitted = false;
                S25LoadingApi.destroy(resourceForm);
                this.data.closeModal();

                return StateService.gotoItem({ itemTypeId: 6, itemId: resp.results.info.id, forceView: "details" });
            })
            .catch((error) => {
                S25LoadingApi.destroy(resourceForm);
                this.errorMsg = error.error.results.error.msg;
                this.errorFocus();
                this.disableSaveButton = false;
            });
    }

    createOrg() {
        this.isSubmitted = true;

        if (this.orgForm.invalid) {
            return this.errorFocus();
        }

        const orgForm: HTMLElement = document.querySelector(".createObjectForm");
        S25LoadingApi.init(orgForm);
        this.disableSaveButton = true;

        return OrganizationService.createOrganization(this.orgName.value, this.orgTitle.value)
            .then((resp) => {
                this.errorMsg = "";
                this.isSubmitted = false;
                S25LoadingApi.destroy(orgForm);
                this.data.closeModal();

                return StateService.gotoItem({ itemTypeId: 2, itemId: resp.results.info.id, forceView: "details" });
            })
            .catch((error) => {
                S25LoadingApi.destroy(orgForm);
                this.errorMsg = error.error.results.error.msg;
                this.errorFocus();
                this.disableSaveButton = false;
            });
    }

    createContact() {
        this.isSubmitted = true;

        if (this.contactForm.invalid) {
            return this.errorFocus();
        }

        const contactForm: HTMLElement = this.elementRef.nativeElement.querySelector(".submit-buttons");
        contactForm && S25LoadingApi.init(contactForm);
        this.disableSaveButton = true;

        const payload = {
            first_name: this.fname.value,
            last_name: this.lname.value,
            middle_name: this.mname.value,
            address: [{ address_type: 3, email: this.email.value, phone: this.phone.value, status: "new" }],
        };

        return ContactService.setContact(payload, true)
            .then((resp) => {
                contactForm && S25LoadingApi.destroy(contactForm);
                this.showSuccessMsg = true;
                this.errorMsg = "";
                this.isSubmitted = false;

                this.newContactId = this.eventForm && resp?.results.info.id;

                if (!this.eventForm) {
                    this.data.closeModal();
                    return StateService.gotoItem({
                        itemTypeId: 3,
                        itemId: resp?.results.info.id,
                        forceView: "details",
                    });
                }
            })
            .catch((error) => {
                contactForm && S25LoadingApi.destroy(contactForm);
                this.errorMsg = error.error.results.error.msg;
                this.errorFocus();
                this.disableSaveButton = false;
            });
    }

    findDuplicateContacts() {
        if (this.dupContactMessage || this.contactForm.invalid) {
            this.cancelConfirm();
            this.createContact();
        } else {
            const contactForm: HTMLElement = this.elementRef.nativeElement.querySelector(".submit-buttons");
            contactForm && S25LoadingApi.init(contactForm);
            this.disableSaveButton = true;

            const inputs = this.elementRef.nativeElement.querySelectorAll("input");
            if (inputs && inputs.length && inputs.length > 0) {
                inputs.forEach((input: HTMLInputElement) => {
                    input.disabled = true;
                });
            }

            return ContactService.getEmailCount(this.email.value).then((count) => {
                if (count > 0) {
                    return ContactService.getContactByEmail(this.email.value, this.lname.value).then((data) => {
                        const matches = S25Util.array.forceArray(data?.contacts?.contact);
                        const exactMatches = matches?.filter((contact: any) => {
                            return (
                                contact?.last_name.toLowerCase() === this.lname.value.toLowerCase() &&
                                S25Util.array.forceArray(contact?.address as any[])[0]?.email.toLowerCase() ===
                                    this.email.value.toLowerCase()
                            );
                        });

                        if (exactMatches && exactMatches.length && exactMatches.length > 0) {
                            this.dupContactMessage =
                                "The provided work email is currently registered to the following contact record(s). Please confirm new contact.";
                            this.exactContactMatches = exactMatches?.map((contact: any) => contact.contact_name);
                        } else {
                            this.dupContactMessage = `The provided work email is currently registered to ${count} contact record(s). Please confirm new contact.`;
                        }

                        contactForm && S25LoadingApi.destroy(contactForm);
                        this.cd.detectChanges();

                        const confirmButton =
                            this.elementRef.nativeElement.querySelector(".aw-button--danger--outline");
                        confirmButton?.focus();
                    });
                } else {
                    this.createContact();
                }
            });
        }
    }

    cancelConfirm() {
        this.dupContactMessage &&= undefined;
        this.exactContactMatches &&= [];
        this.disableSaveButton = false;

        const inputs = this.elementRef.nativeElement.querySelectorAll("input");
        if (inputs && inputs.length && inputs.length > 0) {
            inputs.forEach((input: HTMLInputElement) => {
                input.disabled = false;
            });
        }

        this.cd.detectChanges();
    }

    createLocation() {
        this.isSubmitted = true;

        if (this.locationForm.invalid) {
            return this.errorFocus();
        }

        const locationForm: HTMLElement = document.querySelector(".createObjectForm");
        S25LoadingApi.init(locationForm);
        this.disableSaveButton = true;

        return SpaceService.createLocation(this.locationName.value, this.maxCapacity.value, this.formalName.value)
            .then((resp: Location.MicroItem) => {
                this.errorMsg = "";
                this.isSubmitted = false;
                S25LoadingApi.destroy(locationForm);
                this.data.closeModal();

                return StateService.gotoItem({ itemTypeId: 4, itemId: resp.id, forceView: "details" });
            })
            .catch((error) => {
                S25LoadingApi.destroy(locationForm);
                this.errorMsg = error.error.results.error.msg;
                this.errorFocus();
                this.disableSaveButton = false;
            });
    }

    toggleContactForm() {
        this.formatContactData(this.contactForm.value);
        this.extendedContactForm = !this.extendedContactForm;
    }

    formatContactData(contactData: any): void {
        const hasData = Object.values(contactData).some((value) => value);
        if (!hasData) return;

        const { fname, mname, lname, email, phone } = contactData;

        this.contactTransferData = {
            first_name: fname,
            middle_name: mname,
            last_name: lname,
            work_address: { email, phone, street: "", country: "", addrType: 3, city: "", zip: "", state: "", fax: "" },
        };

        this.cd.detectChanges();
    }

    goToRecord() {
        if (this.newContactId) {
            StateService.gotoItem(
                {
                    itemTypeId: 3,
                    itemId: this.newContactId,
                    forceView: "details",
                },
                true,
            );
        }
    }

    get fname() {
        return this.contactForm.get("fname");
    }

    get mname() {
        return this.contactForm.get("mname");
    }

    get lname() {
        return this.contactForm.get("lname");
    }

    get email() {
        return this.contactForm.get("email");
    }

    get phone() {
        return this.contactForm.get("phone");
    }

    get orgName() {
        return this.orgForm.get("orgName");
    }

    get orgTitle() {
        return this.orgForm.get("orgTitle");
    }

    get locationName() {
        return this.locationForm.get("locationName");
    }

    get formalName() {
        return this.locationForm.get("formalName");
    }

    get maxCapacity() {
        return this.locationForm.get("maxCapacity");
    }

    get resourceName() {
        return this.resourceForm.get("resourceName");
    }
}
